<template>
<div class="query-bill">
    <div class="content">
        <router-view/>
    </div>
    <v-bottom-navigation
        class="tab-bar"
        v-model="tab"
        color="primary"
    >
        <v-btn value="home">
            <span>校园服务</span>
            <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn value="me">
            <span>我的</span>
            <v-icon>mdi-account</v-icon>
        </v-btn>
    </v-bottom-navigation>
</div>
</template>

<script>
export default {
    name: 'QueryBill',

    data() {
        return {
            tab: 'index',
            test: ''
        }
    },

    beforeRouteUpdate(to, from, next) {
        this.tab = to.name;
        next();
    },

    beforeMount() {
        this.tab = this.$route.name;
        this.$watch('tab', (val) => {
            this.$router.push({name: val});
        })
    },
}
</script>

<style lang="scss" scoped>
.query-bill {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    .content {
        height: calc(100% - 56px);
    }
    .tab-bar {
        flex: 0 0 auto;
        align-items: center;
        width: 100%;
        button {
            width: 50%;
            max-width: 50% !important;
            height: 100%;
            &::before {
                opacity: 0 !important;
            }
        }
    }
}
</style>
